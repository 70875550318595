.profile {
  display: flex;
  margin: 3% 3% 3% 3%;
  padding: 1% 2%;
  background-color: #252525;
  background-size: cover;
  color: aliceblue;
  border-radius: 10px;
}

h1 {
  margin-bottom: 0;
}

.block {
  display: flex;
  flex-direction: column;
}

.image-container {
  display: flex;
  width: 7rem;
  height: 7rem;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 3rem;
  box-shadow: 0px 0px 10px rgba(170, 169, 169, 0.5);
}

.line {
  margin: 15px 0;
  border: 1px solid #222222;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.title {
  display: flex;
  align-items: center;
  margin-right: 2rem;


}


.icon-line {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
}

.icon{
  
  color: #888888;
}
.icon:hover {
  color: var(--primary);
}

.intro {
  display: flex;
  flex-direction: column;
  padding-right: 2rem;
}

.intro p {
  font-size: 1.2rem;
  color: aliceblue;
  margin-bottom: 0;

}

.intro h3 {
  font-size: 1.5rem;

}

@media screen and (max-width: 1280px) {
  .profile {
    display: flex;
    flex-direction: column;
  }

}

@media screen and (max-width: 600px) {

.profile {
  margin: 5% 1%;

  padding: 3% 5%;
  overflow-wrap: break-word;
  
}

  
.intro, .title{
  max-width: 100%;
  margin-right: 0;
  padding-right: 0;
}
.image-container {
  display: none;
}
}