.tags{
    text-transform: uppercase;
    width: 30%;
    display: flex;
    flex-direction: column;
    overflow-wrap: normal;
    text-align: center;
    background-color: #333;
    border-radius: 10px;
}

h3 {
    color: var(--primary) ;
}

h4{
  font-family: 'Armata', sans-serif;
  line-height: 1.2rem;
}

hr {
    border: 1px solid #555;
    margin: 0;
    width: 100%;
}

@media screen and (max-width: 1280px) {
.tags{
    width: 100%;
    margin-top: 2rem;
}
}