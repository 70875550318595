.nav-bar {
  position: fixed;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  color: #fff;
  z-index: 10;
  gap: 20px;
  height: 100vh;
}

.nav-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  z-index: 20;
  position: relative; /* Add relative position to the parent .nav-item */
}

.nav-icon {
  padding: 0.325rem;
  border-radius: 0.313rem;
  transition: background-color 0.3s ease, color 0.3s ease;
  z-index: 30;
}

.nav-icon:hover {
  color: var(--primary);
  background-color: #333;
  display: flex;
  justify-content: flex-start;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.nav-icon:not(:hover):not(.active) {
  transition: background-color 1s ease, color 1s ease;
}

.nav-icon.active {
  color: var(--primary);
}

.nav-icon.active a {
  color: var(--primary);
}

.nav-item figcaption {
  font-size: 0.75rem;
  font-family: 'Armata', sans-serif;
  opacity: 0;
  position: absolute;
  transition: opacity 0.5s ease 0.5s, transform 0.5s ease 0.5s; /* Add transitions for opacity with a 0.5s duration and a 0.5s delay, and transform with a 0.5s duration */
  pointer-events: none; /* Disable pointer events on figcaption when nav-icon is not hovered */

}

.nav-item .nav-icon:hover + figcaption,
.nav-item:hover .nav-icon + figcaption {
  opacity: 1;
  transform: translateX(40px); /* Slide in from right to left when either the nav-icon or nav-item is hovered */
}

.nav-item:not(:hover) .nav-icon + figcaption{
  transform: translateX(0); /* Start off-screen to the right when neither the nav-icon nor nav-item is hovered */
  transition: opacity 0.3s ease 0.1s , transform 0.3s ease 0.1s ; /* Add transitions for opacity with a 0.3s duration and a 0.3s delay, and transform with a 0.3s duration */
}

.navbar hr {
  max-width: 35px;
}

@media screen and (max-width: 769px) {
  .nav-bar {
    display: none;
  }
}
