/* App.css */
.app {
    margin: 0; /* Remove any default margin */
    padding: 0; /* Remove any default padding */
    /* Add other custom styles as needed */
    background-color: #090909;
  }
  

@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@300;400;500;800&family=Open+Sans&display=swap');


:root{
  --primary: #f48d18;

}


body {
  background-color: #090909;
  margin: 0;
}

h1 {
  font-family: 'Armata', sans-serif;
  font-size: 3rem;
  margin-bottom: 12px;
  font-weight: 800;
}

p{
  font-family: 'Open Sans', sans-serif;
}


.highlight {
  background-size: 200%;
  border-radius: 3px;
  padding: 0 5px;
  
}

.highlight.highlighted {
  background-image: linear-gradient(90deg,rgba(0,0,0,0) 50%,var(--text-highlight-color, #444 ) 0);
  color: var(--primary);
  background-position: -100%;
  transition: background-position ease-in-out 3s;

}


.app {
  display: flex;
  justify-content: flex-start;
  overflow-y: hidden;

}

.components {
  margin-left: 5rem;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;

  background-color: #090909;
  overflow-y: auto; /* Enable vertical scrolling for content */
}

@media screen and (max-width: 769px) {

h1 {
  font-size: 2rem;
}
.components{
  margin: 0;
  padding: 0%;

}
}
