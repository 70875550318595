/* Background.css */
@import url('https://fonts.googleapis.com/css2?family=Armata:wght@300;400;500&display=swap');


.background {
    position: relative;
    height: 70dvh;
    background-color: #090909;
    background-position: center ;
    margin: 0;
    padding: 0;
    color: white;
    text-shadow: black 1px 1px 5px ;
    z-index: 1; /* Set a higher z-index for the content */
    overflow: hidden;
    scroll-padding-block-start: 0;

  }
  
  
  /* Add a pseudo-element for the gradient overlay */
  .background::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: radial-gradient(circle, rgba(0,0,0,0.1) 0%, rgba(17,17,17,0.7) 60%, #090909 95%);
    pointer-events: none;
    z-index: 0; /* Set a lower z-index for the overlay */
    box-shadow: inset 0rem -1.5rem 1.5rem 0.5rem #090909;
    scroll-margin-top: 0;
  }
  /* Add the d-flex class to the .content element */



.content {
  position: relative;
  max-width: 60dvw;
  padding: 3% 5%;
  z-index: 2;
  text-align: left;
  display: flex; /* Use flexbox to control alignment */
  flex-direction: column; /* Stack child elements vertically */
  align-items: flex-start; /* Align items (including images) to the left */
}

.content img {
  max-width: 600px;
  max-height: 100px;
  padding: 0;
  margin: 1rem 0;
  object-fit: contain;
}
/* Improved button styles with hover and active states */
.content button {
  padding: 0.75rem 1.25rem;
  margin-top: 1.25rem;
  border-radius: 10px;
  color: #fff;
  border: 3px solid #222;
  background-color: #3f3f3f;
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 0.15rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.content button:hover {
  background-color: #444;
  color: var(--primary);

}





.content button:active {
  transform: scale(0.98); /* Slight scale down on button press */
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.8); /* Increase box shadow on button press */
}

.content h3 {
  margin-bottom: 0;
}



@media screen and (max-width: 600px) {


.content {
 padding: 5%;
}
  .content img {
    max-width: 90dvw;
    padding: 0;
    margin: 1rem 0;
    object-fit: contain;
  }

  .background {
    position: relative;
    height: auto;

  }
  .content button {
    
    font-size: 0.8rem;
   
  }
}