/* Carousel.css */
.carousel {
    display: flex;
    overflow-x: auto; /* Enable horizontal overflow */
    scrollbar-width: none; /* Hide scrollbars for browsers that support it */
    -ms-overflow-style: none; /* Hide scrollbars for Internet Explorer */
    padding: 5px 0px 5px 20px;

  }
  
  .carousel::-webkit-scrollbar {
    display: none; /* Hide scrollbars for Webkit-based browsers (Chrome, Safari, etc.) */
  }
  
  .card {
    width: 250px; /* Adjust card width as needed */
    margin-right: 20px; /* Add some space between cards */
    background-color: #090909;
  }
  /* Add these styles to your Carousel.css */
.arrows{
  display: flex;
  justify-content: flex-end;
  margin: 1% 5%; /* Add some margin between the carousel and the buttons */

}

.arrow-button {
  cursor: pointer;
  color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #222;/* Background color for the arrow buttons */
  border: 2px solid #333; /* Border color for the arrow buttons */
  border-radius: 50%; /* Make the buttons circular */
  height: 35px; /* Adjust the height as needed */
  width: 35px; /* Adjust the width as needed */
  margin: 0px 8px; /* Add some margin between the carousel and the buttons */
  transition: background-color 0.3s ease;
}

.arrow-button:hover {
  background-color: #444;
  color: #fff;
}

.arrow-button.left {
  margin-left: 0;
}

.arrow-button.right {
  margin-right: 0;
}

@media screen and (max-width: 769px) {

.carousel{
  padding: 0%;
}
.arrows{
  
  margin: 3%; /* Add some margin between the carousel and the buttons */

}
}