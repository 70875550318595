
.profissional{
    padding: 3% 5%;
    color: aliceblue;
  background-color: #090909;

}

h2,h3 {
  font-family: 'Armata', sans-serif;
}

.row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 2.5% ;
}


.logo{
    margin: 2.5% 0;
    max-height: 2rem;
    width: auto;
}


@media screen and (max-width: 600px) {

  .logo{
    max-height: 1.2rem;
    width: auto;
}

.profissional{
  padding: 3% 5%;

}

}