.footer {
  padding: 1% 5%;
  color: #fff;
  background-color: #222 ;
  margin: 0;
}

.features{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h4 {
  font-family: 'Armata', sans-serif;
  margin: 5px 0;
}

.icon-footer {
  display: inline-flex;
  padding: 0 5px;
  color: var(--primary) !important;
  cursor: pointer;
}

.icon-footer:hover {
  color: #555 !important;
}


@media screen and (max-width: 769px) {

  .footer{

    display: none;
  }
  }